import React from 'react'
import { Layout } from '../components/layout'
import styled from 'styled-components'

const StyledMain = styled.main`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    min-height: 50vh;

    @media(max-width: 600px) {
        margin-top: 100px;
    }
`; 

const StyledTitle = styled.h1`
    font-size: clamp(100px, 25vw, 200px);
    font-family: var(--display-font);
    color: var(--fc-accent-color-1);
    font-weight: 400;
    margin: 20px 0 0 0;
    line-height: 1;
`;

const StyledSub = styled.h2`
    font-size: clamp(30px, 5vw, 50px);
    margin: 0;
    padding: 0;
    font-weight: 300;
`;

const StyledReturnButton = styled.div`
    .action-button {
        display: inline-block; 
        margin: 50px 5px;
        border: none;
        background-color: var(--fc-accent-color-2);
        color: #fff;
        border-radius: 38px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: .02em;
        padding: 0.5em 2.25em;
        text-decoration: none;
        text-align: center;
        transform: scale(1);
        transition: color 225ms cubic-bezier(0.29, 0.19, 0.405, 1),transform 225ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        line-height: 2.2;
        min-width: 125px;
        box-shadow: 1px 1px 2px 0 rgb(0 0 0 / 15%);
        will-change: transform;
        width: 150px;

        &:hover, 
        &:focus {
            background: var(--fc-accent-color-1);
            transform: scale(1.03);
        }
    }
`; 

const PageNotFound = ({ location }) => {
    return (
        <Layout location={location}>
            <StyledMain>
                <StyledTitle>404</StyledTitle>
                <StyledSub>Not Available</StyledSub>
                <StyledReturnButton><a href="/" className="action-button">Return Home</a></StyledReturnButton>
            </StyledMain>
        </Layout>
    )
}

export default PageNotFound
